import React from 'react'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import Image from 'next/image'
import { IContentfulAsset } from '@/interfaces'

import IconCheck from '@/assets/img/img-segmentation/check.png'

import IconExplorador from '@/assets/img/img-segmentation/icon-explorador.png'
import IconAprediz from '@/assets/img/img-segmentation/icon-aprendiz.png'
import IconProfession from '@/assets/img/img-segmentation/icon-profesional.png'
import IconMaestro from '@/assets/img/img-segmentation/icon-maestro.png'
import IconLeyenda from '@/assets/img/img-segmentation/icon-leyenda.png'

interface SelectedLevelProps {
  selectedLevel: {
    nameLevel: 'Explorador' | 'Aprendiz' | 'Profesional' | 'Maestro' | 'Leyenda'
    imgDetails: IContentfulAsset
    accumulatedSales: string
    listBenefits: {
      [key: string]: string
    }
  }
  handleCloseModal: () => void // Add this line
}

const IconLevels = {
  Explorador: IconExplorador,
  Aprendiz: IconAprediz,
  Profesional: IconProfession,
  Maestro: IconMaestro,
  Leyenda: IconLeyenda
}

const ModalItemsLevel = ({
  selectedLevel,
  handleCloseModal
}: SelectedLevelProps) => {
  return (
    <div className={styles.root__modalLevelOverlay}>
      <div className={styles.root__modalLevel}>
        <div className={styles.root__modalLevel_header}>
          <div className={styles.root__modalLevel_header__level}>
            <Image
              className={styles.root__modalLevel_header__level__icon}
              src={IconLevels[selectedLevel.nameLevel]}
              alt={'Imagen del nivel de Kliiker'}
              priority
            />
            <span>{selectedLevel.nameLevel}</span>
          </div>
          <button
            className={styles.root__modalLevel_header_btnClose}
            onClick={handleCloseModal}>
            <FeatherIcon icon="x" color="#1D1C1D" />
          </button>
        </div>
        <img
          src={selectedLevel?.imgDetails.url}
          alt={selectedLevel.nameLevel}
        />
        <p className={styles.root__modalLevel__description}>
          Ventas acumuladas hasta {selectedLevel.accumulatedSales}
        </p>
        <ul className={styles.root__modalLevel__listBenefits}>
          {Object.values(selectedLevel.listBenefits)?.map((benefit, index) => (
            <li key={index} className={styles.root__modalLevel__itemBenefits}>
              <Image
                className={styles.root__modalLevel__itemBenefits__icon}
                src={IconCheck}
                alt={'Icono Check'}
                priority
              />
              <span className={styles.root__modalLevel__itemBenefits__text}>
                {benefit}
              </span>
            </li>
          ))}
        </ul>
        <div className={styles.root__modalLevel__container_btn}>
          <button
            className={styles.root__modalLevel__container_btn_accept}
            onClick={handleCloseModal}>
            Aceptar
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalItemsLevel
