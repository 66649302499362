import FeatherIcon from 'feather-icons-react'
import { formatAmount } from '@/functions'
import { IPerformance } from '@/interfaces/performance-kliikers/performance'
import { useRouter } from 'next/router'

import styles from './index.module.scss'
interface IPerformanceTBodyProps {
  kliiker?: IPerformance
}

export default function PerformanceTBody({ kliiker }: IPerformanceTBodyProps) {
  const router = useRouter()
  return (
    <tbody
      className={styles.root}
      aria-label={'Detalles del kliiker'}
      role={'group'}>
      <tr
        className={styles.root__data}
        onClick={() => {
          kliiker !== undefined &&
            router.push(`/admin/kliiker?id=${kliiker.cedula}`)
        }}>
        <td>{kliiker?.cedula}</td>
        <td>{kliiker?.nombre}</td>
        {/* <td>{kliiker?.apellido}</td> */}
        <td>{kliiker?.ciudad}</td>
        <td>{formatAmount(kliiker?.totalVenta)}</td>
        <td>{kliiker?.pedidos}</td>
        <td>{kliiker?.porcentajeActividad}%</td>
        <td className={styles.root__in_development}>Consecutivo</td>
        <td className={styles.root__in_development}>Aprendiz</td>
        <td>{formatAmount(kliiker?.ticketPromedio)}</td>
        <td>
          {kliiker !== undefined && (
            <FeatherIcon className={styles.root__icon} icon={'chevron-right'} />
          )}
        </td>
      </tr>
    </tbody>
  )
}
