// import { useEffect, useState } from 'react'
import Link from 'next/link'
import { adminMenuItems, kliikerMenuItems, TButtonAction } from './index.data'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import { useSession } from 'next-auth/react'
// import NewContentBadge from '@atoms/new-content-badge'
// import * as Cookies from 'js-cookie'

interface IUserMenuProps {
  activeIndex?: number
  onButtonClick?: (action: TButtonAction) => void
}

export default function UserMenu({
  activeIndex = -1,
  onButtonClick = () => {}
}: IUserMenuProps) {
  const session = useSession()
  const userRole = session.data?.user.roleId
  const listItem = userRole === 1 ? adminMenuItems : kliikerMenuItems

  // const [hasVisitedSection, setHasVisitedSection] = useState<{
  //   [key: string]: boolean
  // }>({})

  // useEffect(() => {
  //   const newVisitedSections: { [key: string]: boolean } = {}
  //   listItem.forEach((_, key) => {
  //     const cookieName = `content_viewed_${key}`.toLowerCase()
  //     newVisitedSections[key] = Cookies.get(cookieName) === 'true'
  //   })
  //   setHasVisitedSection(newVisitedSections)
  // }, [listItem])

  // const handleLinkClick = (key: number) => {
  //   const cookieName = `content_viewed_${key}`.toLowerCase()
  //   Cookies.set(cookieName, 'true', { expires: 7 })
  // }

  return (
    <menu
      aria-label="Opciones de navegación de usuario"
      role={'menu'}
      className={styles.root}>
      {listItem.map((item, key) => {
        return (
          <li
            key={key}
            className={
              styles.root__item +
              (activeIndex === key ? ` ${styles['root__item--active']}` : '')
            }>
            {item.type === 'link' ? (
              <Link
                href={item.href}
                className={styles.root__link}
                // onClick={() => handleLinkClick(key)}
              >
                <FeatherIcon icon={item.icon} className={styles.root__icon} />
                {item.label}
                {/* <NewContentBadge
                  publicationDate={item.publicationDate}
                  contentId={key}
                  hasVisitedSection={hasVisitedSection[key]}
                /> */}
              </Link>
            ) : (
              <button
                type="button"
                className={styles.root__button}
                onClick={() => {
                  onButtonClick(item.action)
                }}>
                <FeatherIcon icon={item.icon} className={styles.root__icon} />
                {item.label}
              </button>
            )}
          </li>
        )
      })}
    </menu>
  )
}
