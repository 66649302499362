import { IKliikerPreRegisterFormFields } from '@/interfaces'
import { kliikerPreRegisterFormService } from '@/services'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { IMessageToast } from '@atoms/index'
import { PreRegisterForm } from '@molecules/index'
import Link from 'next/link'
import Image from 'next/image'
import { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import styles from './index.module.scss'
import { selectPreRegister, setFulfillment } from './index.slice'

import PreregisterBenefitCards from '@molecules/pre-register-benefit-cards'
import PreregisterBrandsSlider from '@molecules/pre-register-brands-slider'
import PreregisterOurCommunity from '@molecules/pre-register-our-community'

import welcomeImage from '@/assets/img/register-page/main-image-banner.png'
import block1Image from '@/assets/img/register-page/Group2308.png'

export default function PreRegisterSection() {
  const [messages, setMessages] = useState<IMessageToast[]>([])
  const { fulfillment } = useAppSelector(selectPreRegister)
  const dispatch = useAppDispatch()

  const handleFormSubmit: SubmitHandler<IKliikerPreRegisterFormFields> = async (
    data
  ) => {
    try {
      await kliikerPreRegisterFormService(data)
      dispatch(setFulfillment(true))
    } catch (ex) {
      setMessages((current) => [
        ...current,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  return (
    <section role={'group'} className={styles.root}>
      <div className={styles.root__banner}>
        <div className={styles.root__banner_container}>
          <hgroup className={styles.root__heading}>
            <h1 className={styles.root__title}>
              <span className={styles.root__welcome}>EN NUESTRA COMUNIDAD</span>
              <span className={styles.root__weare_free}>
                <b className={styles.root__weare}>SOMOS </b>
                <b className={styles.root__free}>LIBRES,</b>
              </span>
              <span className={styles.root__weare_kliikers}>
                <b className={styles.root__weare}>SOMOS </b>
                <b className={styles.root__kliikers}>KLIIKERS</b>
              </span>
            </h1>

            <Image
              className={styles['root__image__banner-register-mobile']}
              src={welcomeImage}
              alt={'Imagen Banner de registro a Kliiker'}
              width={190}
              priority
            />

            <p className={styles.root__join}>¡Únete ahora!</p>

            {/*! fulfillment && (
              <p className={styles.root__description}>
                Por favor diligencia los siguientes datos y haz parte de nuestra
                comunidad de vendedores.
              </p>
            ) */}
          </hgroup>

          <div className={styles['root__content-wrapper']}>
            {!fulfillment ? (
              <div className={styles['root__form-wrapper']}>
                <h3 className={styles.root__form_title}>
                  Regístrarte Gratis y&nbsp;
                  <br className={styles.root__form_breakline_mobile} />
                  Comienza a Ganar Dinero
                </h3>
                <p className={styles.root__form_description}>
                  Diligencia el formulario de inscripción y
                  <br className={styles.root__form_breakline_desktop} />
                  se parte de nuestra comunidad de vendedores.
                  <br className={styles.root__form_breakline_desktop} />
                  Recibe tu código de vendedor en minutos.;
                  <br />
                  <br />
                  <strong> Es fácil y rápido.</strong>
                </p>

                <PreRegisterForm
                  messages={messages}
                  onMessageDismiss={(index) =>
                    setMessages((currentMessages) =>
                      currentMessages.filter(
                        (_, messageIndex) => index !== messageIndex
                      )
                    )
                  }
                  onSubmit={handleFormSubmit}
                />
              </div>
            ) : (
              <div className={styles['root__fulfillment-wrapper']}>
                <h2 className={styles.root__subtitle}>¡Revisa tu correo!</h2>
                <p className={styles['root__fulfillment-message']}>
                  Dirígete a la bandeja de tu correo electrónico para terminar
                  tu registro de manera exitosa. Ahí encontrarás todas las
                  instrucciones para finalizar tu registro.
                </p>
                <p className={styles['root__fulfillment-footnote']}>
                  * Échale un ojo también a tu bandeja de correo no deseado.
                </p>
                <Link href={'/'} className={styles['root__fulfillment-button']}>
                  Aceptar
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.root__block_1}>
        <div className={styles.root__block_1_container}>
          <div className={styles.root__block_1_image}>
            <Image
              className={styles.root__image__block_1}
              src={block1Image}
              alt={'Conviértete en un Emprendedor Digital con Kliiker'}
              priority
            />
          </div>
          <div className={styles.root__block_1_text}>
            <h3 className={styles.root__block_1_text_heading}>
              Conviértete en un <span>Emprendedor</span>
              <br className={styles.root__form_breakline_desktop} />
              Digital con Kliiker
            </h3>
            <p className={styles.root__block_1_text_paragraph} title="no-title">
              Somos una plataforma virtual que te brinda la oportunidad de ganar
              comisión por tus ventas. Reunimos más de{' '}
              <strong>20 mil productos y 90 marcas reconocidas</strong> de moda
              y cuidado personal.
              <br />
              <br />
              !Únete a nuestra comunidad de vendedores exitosos y gana dinero
              cada semana!
            </p>
          </div>
        </div>
      </div>

      <div className={styles.root__block_2}>
        <div className={styles.root__block_2_container}>
          <iframe
            className={styles.root__block_2_video_iframe}
            src="https://www.youtube.com/embed/nIGqPUiFZ7g?si=RbeikmRyNtoHSoaX"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            frameBorder={0}
            allowFullScreen></iframe>
        </div>
      </div>

      <div className={styles.root__block_3}>
        <div className={styles.root__block_3_container}>
          <h3 className={styles.root__block_3_heading}>
            MARCAS PARA <span>PROMOCIONAR Y GANAR</span>
          </h3>

          <div className={styles.root__block_3_slider_container}>
            <PreregisterBrandsSlider />
          </div>
        </div>
      </div>

      <div className={styles.root__block_4}>
        <div className={styles.root__block_4_container}>
          <h3 className={styles.root__block_4_heading}>
            Ventajas de Ser Parte de <span>Kliiker</span>
          </h3>

          <div className={styles.root__block_4_cards_container}>
            <PreregisterBenefitCards />
          </div>
        </div>
      </div>

      <div className={styles.root__block_5}>
        <div className={styles.root__block_5_container}>
          <h3 className={styles.root__block_5_heading}>
            nuestra <span>comunidad</span>
          </h3>

          <div className={styles.root__block_5_cards_container}>
            <PreregisterOurCommunity />
          </div>
        </div>
      </div>

      {/*
        <SVGComponent
          svgPath="home-hero/top-decoration"
          className={styles.root__decoration}
        />
        */}
    </section>
  )
}
