import { IKliikerFilterDateFormFields, ISelectOptions } from '@/interfaces'
import { IMessageToast, SearchInput, SelectGroup } from '@atoms/index'
import { useEffect, useState } from 'react'
import { TableWrapper } from '..'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useSession } from 'next-auth/react'
import {
  IAdminPerformanceQueryOptions,
  TAdminPerformanceFieldOrder,
  // getAdminPerformanceKpis,
  getAdminPerformanceReportService,
  getAdminPerformanceService
} from '@/services'
import { PerformanceTable, DateForm } from '@molecules/index'
import {
  IPerformance
  // IPerformanceKpisResponse
} from '@/interfaces/performance-kliikers/performance'
// import FeatherIcon from 'feather-icons-react'
// import IconKliikerTotals from '@/assets/img/img-segmentation/u_users-alt.png'
// import IconOrders from '@/assets/img/img-segmentation/u_shopping-bag.png'
// import IconActivity from '@/assets/img/img-segmentation/u_shop.png'
// import Image from 'next/image'
// import { PerformanceModal } from '@molecules/kliiker-performance-modal'

import styles from './index.module.scss'
// import { convertToCOP } from '@/utils/convertToCOP'

export default function AdminPerformanceSection() {
  const tableSize = 20
  const [tableData, setTableData] = useState<IPerformance[]>([])
  const [messages, setMessages] = useState<IMessageToast[]>([])
  const [pageActive, setPageActive] = useState(1)
  const [countRegister, setCountRegister] = useState(1)
  const [countPages, setCountPages] = useState(1)
  const [ascending, setAscending] = useState(true)
  const [tableOptions, setTableOptions] =
    useState<IAdminPerformanceQueryOptions>({ size: tableSize })
  const selectsFormObject = useForm<{
    city: string
    orderBy: string
    orderDirection: string
  }>()
  const [cityOptions, setCityOptions] = useState<ISelectOptions[]>([])
  const [documentSearch, setDocumentSearch] = useState<string>('')
  const { data } = useSession()
  const idToken = data?.idToken

  // const [isModalOpen, setIsModalOpen] = useState(false)
  // const [performanceDataKpis, setPerformanceDataKpis] =
  //   useState<IPerformanceKpisResponse>()

  // useEffect(() => {
  //   async function fetchKpis() {
  //     if (idToken === undefined) return
  //     if (tableOptions.endDate !== undefined) {
  //       const yearMonth = `${tableOptions.endDate.getFullYear()}-${(
  //         tableOptions.endDate.getMonth() + 1
  //       )
  //         .toString()
  //         .padStart(2, '0')}`
  //       try {
  //         const response: any = await getAdminPerformanceKpis({
  //           idToken,
  //           yearMonth
  //         })
  //         setPerformanceDataKpis(response)
  //       } catch (ex) {
  //         console.error('Error fetching performance KPIs:', ex)
  //       }
  //     } else {
  //       try {
  //         const response: any = await getAdminPerformanceKpis({
  //           idToken
  //         })
  //         setPerformanceDataKpis(response)
  //       } catch (ex) {
  //         console.error('Error fetching performance KPIs:', ex)
  //       }
  //     }
  //   }
  //   fetchKpis()
  // }, [idToken, tableOptions])

  const handlePageChange = (page: number) => {
    setPageActive(page)
  }

  const handleOnDissmiss = (index: number) => {
    setMessages((currentMesages) =>
      currentMesages.filter((_, curentIndex) => index !== curentIndex)
    )
  }

  const handleFilterFormSubmit: SubmitHandler<
    IKliikerFilterDateFormFields
  > = async (data) => {
    try {
      const newDates: { startDate?: Date; endDate?: Date } = {
        startDate: undefined,
        endDate: undefined
      }
      if (data.startDate !== undefined) {
        if (data.startDate === '') {
          sessionStorage.removeItem('startDateP')
          sessionStorage.removeItem('endDateP')
        } else {
          newDates.startDate = new Date(data.startDate)
          sessionStorage.setItem('startDateP', data.startDate)
        }
      }
      if (data.endDate !== undefined) {
        if (data.startDate === '') {
          sessionStorage.removeItem('startDateP')
          sessionStorage.removeItem('endDateP')
        } else {
          newDates.endDate = new Date(data.endDate)
          sessionStorage.setItem('endDateP', data.endDate)
        }
      }
      setPageActive(1)
      setTableOptions((oldOptions) => ({
        ...oldOptions,
        ...newDates
      }))
    } catch (ex) {
      setMessages((current) => [
        ...current,
        { type: 'error', message: (ex as Error).message }
      ])
    }
  }

  const handleSearchInput = (value: string) => {
    setDocumentSearch(value)
  }

  useEffect(() => {
    async function fetchTable() {
      if (idToken === undefined) return
      try {
        const newOptions = { ...tableOptions }
        const endDate = sessionStorage.getItem('endDateP') ?? ''
        const startDate = sessionStorage.getItem('startDateP') ?? ''
        if (startDate !== '') {
          newOptions.startDate = new Date(startDate)
        }
        if (endDate !== '') {
          newOptions.endDate = new Date(endDate)
        }

        const response = await getAdminPerformanceService({
          idToken,
          queryOptions: {
            ...newOptions,
            page: pageActive - 1,
            size: tableSize,
            documentNumber: documentSearch
          }
        })
        const fixedData: IPerformance[] =
          response.userPerformancePage.data.map<IPerformance>((value) => ({
            cedula: value.document,
            nombre: value.firstname,
            apellido: value.lastname,
            ciudad: value.city,
            totalVenta: value.totalSales,
            pedidos: value.ordersCount,
            porcentajeActividad: value.activityPercent,
            ticketPromedio: value.averageTicket
          }))
        setTableData(fixedData)
        setCountRegister(response.userPerformancePage.countRegister)
        setCountPages(response.userPerformancePage.countPage)
        setCityOptions(
          response.citiesNames.map<ISelectOptions>((value) => ({
            label: value,
            value
          }))
        )
      } catch (ex) {}
    }
    fetchTable()
  }, [idToken, tableOptions, pageActive, documentSearch])

  return (
    <section
      role={'contentinfo'}
      aria-label={'Sección del perfil del Kliiker'}
      className={styles.root}>
      <div className={styles['root__container-filter']}>
        <DateForm
          messages={messages}
          onMessageDismiss={handleOnDissmiss}
          onSubmit={handleFilterFormSubmit}
          isOnlyIcon
          typeIcon="search"
        />
        <div className={styles['root__select-groups']}>
          <div className={styles.root__group}>
            <SelectGroup
              label="Ciudad"
              field={{ id: 'city', formObject: selectsFormObject }}
              itemsFiller={() => [
                { label: 'Todas', value: 'all' },
                ...cityOptions
              ]}
              onChange={(value) => {
                setTableOptions((oldOptions) => ({
                  ...oldOptions,
                  city: value === 'all' ? undefined : value
                }))
              }}
            />
          </div>
          <div className={styles.root__group}>
            <SelectGroup
              label="Ordenar por"
              field={{ id: 'orderBy', formObject: selectsFormObject }}
              itemsFiller={() => [
                { label: 'Total venta', value: 'total_sales' },
                { label: 'Pedidos', value: 'orders_count' },
                { label: '% Actividad', value: 'activity_percentage' },
                { label: 'Ticket prom.', value: 'average_ticket' }
              ]}
              onChange={(value) =>
                setTableOptions((oldOptions) => ({
                  ...oldOptions,
                  fieldOrder: value as TAdminPerformanceFieldOrder
                }))
              }
            />
          </div>
          {/* <div className={styles.root__group}>
            <SelectGroup
              label="Colocacion"
              field={{ id: 'orderDirection', formObject: selectsFormObject }}
              itemsFiller={() => [
                { label: 'Menor a mayor', value: 'asc' },
                { label: 'Mayor a menor', value: 'desc' }
              ]}
              onChange={(value) =>
                setTableOptions((oldOptions) => ({
                  ...oldOptions,
                  ascending: value === 'asc'
                }))
              }
            />
          </div> */}
          <div
            className={`${styles.root__group} ${styles.root__in_development}`}>
            <SelectGroup
              label="Segmentación"
              field={{ id: 'orderDirection', formObject: selectsFormObject }}
              itemsFiller={() => [
                { label: 'Todas', value: 'all' },
                { label: 'Explorador', value: 'explorador' },
                { label: 'Aprendiz', value: 'aprendiz' },
                { label: 'Profesional', value: 'pro' },
                { label: 'Maestro', value: 'maestro' },
                { label: 'Leyenda', value: 'leyenda' }
              ]}
              onChange={(value) => {
                setTableOptions((oldOptions) => ({
                  ...oldOptions,
                  seg: value === 'all' ? undefined : value
                }))
              }}
            />
          </div>
          <div
            className={`${styles.root__group} ${styles.root__in_development}`}>
            <SelectGroup
              label="Estado"
              field={{ id: 'orderDirection', formObject: selectsFormObject }}
              itemsFiller={() => [
                { label: 'TOTAL', value: 'total' },
                { label: 'SIN ID', value: 'sinId' },
                { label: 'INACTIVO 1', value: 'inactivo1' },
                { label: 'INACTIVO 2', value: 'inactivo2' },
                { label: 'INACTIVO 3', value: 'inactivo3' },
                { label: 'INACTIVO 4', value: 'inactivo4' },
                { label: 'INACTIVO 5', value: 'inactivo5' },
                { label: 'INACTIVO 6', value: 'inactivo6' },
                { label: 'INACTIVO 7', value: 'inactivo7' },
                { label: 'INACTIVO > 7', value: 'inactivo>7' },
                { label: 'NUEVO', value: 'nuevo' },
                { label: 'REACTIVO 1', value: 'reactivo1' },
                { label: 'REACTIVO 2', value: 'reactivo2' },
                { label: 'REACTIVO 3', value: 'reactivo3' },
                { label: 'REACTIVO 4', value: 'reactivo4' },
                { label: 'REACTIVO 5', value: 'reactivo5' },
                { label: 'REACTIVO 6', value: 'reactivo6' },
                { label: 'REACTIVO 7', value: 'reactivo7' },
                { label: 'CONSECUTIVO', value: 'consecutivo' },
                { label: 'REINGRESO', value: 'reingreso' }
              ]}
              onChange={(value) => {
                setTableOptions((oldOptions) => ({
                  ...oldOptions,
                  seg: value === 'total' ? undefined : value
                }))
              }}
            />
          </div>
        </div>
        <SearchInput
          placeholder="Digite la cedula aquí"
          onChange={handleSearchInput}
          onClick={() => {
            setDocumentSearch('')
            setPageActive(1)
          }}
          value={documentSearch}
        />
      </div>
      {/* <div className={styles.root__stadistics}>
        <div
          className={`${styles.root__stadistics__item} ${styles.root__stadistics__item__total}`}>
          <Image
            className={styles.root__panel__icon}
            src={IconOrders}
            alt="Kliikers"
            width={IconKliikerTotals.width}
            height={IconKliikerTotals.height}
            priority
          />
          <div className={styles.root__stadistics__item__content}>
            <h5 className={styles.root__stadistics__item__content__h5}>
              Kliikers Activos
            </h5>
            <div className={styles.root__stadistics__item__sales_kliikers}>
              <div
                className={
                  styles.root__stadistics__item__sales_kliikers__category
                }>
                <span
                  className={
                    styles.root__stadistics__item__sales_kliikers__category_amount
                  }>
                  {performanceDataKpis?.totalKliikers ?? 0}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${styles.root__stadistics__item} ${styles.root__stadistics__item__sales}`}>
          <Image
            className={styles.root__panel__icon}
            src={IconActivity}
            alt={'Kliikers'}
            priority
          />
          <div className={styles.root__stadistics__item__content}>
            <h5 className={styles.root__stadistics__item__content__h5}>
              Total ventas
            </h5>
            <div className={styles.root__stadistics__item__sales_breakdown}>
              <div
                className={
                  styles.root__stadistics__item__sales_breakdown__category
                }>
                <span
                  className={
                    styles.root__stadistics__item__sales_breakdown__category_label
                  }>
                  Kliikers
                </span>
                <span
                  className={
                    styles.root__stadistics__item__sales_breakdown__category_amount
                  }>
                  {convertToCOP(performanceDataKpis?.kliikerSales ?? 0)}
                </span>
                <span
                  className={
                    styles.root__stadistics__item__sales_breakdown__category_amount
                  }>
                  {performanceDataKpis?.kliikerSalesPercentage}%
                </span>
              </div>
              <div
                className={
                  styles.root__stadistics__item__sales_breakdown__category
                }>
                <span
                  className={
                    styles.root__stadistics__item__sales_breakdown__category_label
                  }>
                  Cliente Final
                </span>
                <span
                  className={
                    styles.root__stadistics__item__sales_breakdown__category_amount
                  }>
                  {convertToCOP(performanceDataKpis?.salesWithoutKliiker ?? 0)}
                </span>
                <span
                  className={
                    styles.root__stadistics__item__sales_breakdown__category_amount
                  }>
                  {performanceDataKpis?.salesWithoutKliikerPercentage}%
                </span>
              </div>
            </div>

            <button
              className={styles.root__stadistics__item__button}
              onClick={() => setIsModalOpen(!isModalOpen)}>
              <FeatherIcon icon="info" />
            </button>
          </div>
          {isModalOpen && (
            <div className={styles.root__stadistics__item__modal}>
              <PerformanceModal
                idToken={idToken}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                infoResume={
                  performanceDataKpis ?? {
                    data: [],
                    kliikerSales: 0,
                    kliikerSalesPercentage: 0,
                    salesWithoutKliiker: 0,
                    salesWithoutKliikerPercentage: 0,
                    totalKliikers: 0,
                    consecutive: {
                      percentConsecutives: 0,
                      items: []
                    }
                  }
                }
              />
            </div>
          )}
        </div>
        <div
          className={`${styles.root__stadistics__item} ${styles.root__stadistics__item__activity}`}>
          <Image
            className={styles.root__panel__icon}
            src={IconKliikerTotals}
            alt={'Kliikers'}
            priority
          />
          <div className={styles.root__stadistics__item__content}>
            <div className={styles.root__stadistics__item__content_head}>
              <h5 className={styles.root__stadistics__item__content__h5}>
                Consecutividad
              </h5>
              <span className={styles.root__stadistics__item__content__span}>
                {performanceDataKpis?.consecutive.percentConsecutives}%
              </span>
            </div>
            <div className={styles.root__stadistics__item__sales_breakdown}>
              <div
                className={
                  styles.root__stadistics__item__sales_breakdown__category
                }>
                <span
                  className={
                    styles.root__stadistics__item__sales_breakdown__category_label
                  }>
                  Mes Anterior
                </span>
                <span
                  className={
                    styles.root__stadistics__item__sales_breakdown__category_amount
                  }>
                  {performanceDataKpis?.consecutive.items[0]?.total ?? 0}
                </span>
              </div>
              <div
                className={
                  styles.root__stadistics__item__sales_breakdown__category
                }>
                <span
                  className={
                    styles.root__stadistics__item__sales_breakdown__category_label
                  }>
                  Mes Actual
                </span>
                <span
                  className={
                    styles.root__stadistics__item__sales_breakdown__category_amount
                  }>
                  {performanceDataKpis?.consecutive.items[1]?.total ?? 0}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className={styles.root__content}>
        <TableWrapper
          title="Listado de Kliikers"
          elements={[
            {
              label: 'Colocación',
              icono: 'repeat',
              handleClick: async () => {
                const newAscending = !ascending
                setAscending(newAscending)

                setTableOptions((oldOptions) => ({
                  ...oldOptions,
                  ascending: newAscending
                }))
              }
            },
            {
              label: 'DESCARGAR REPORTE',
              icono: 'download-cloud',
              handleClick: async () => {
                try {
                  if (idToken === undefined)
                    throw new Error('¡Algo salió mal!😰', {
                      cause: 'No-Id-Token'
                    })
                  const response = await getAdminPerformanceReportService({
                    idToken,
                    queryOptions: { ...tableOptions, page: pageActive - 1 }
                  })
                  window.open(response.url, '_blank')
                } catch (ex) {
                  setMessages((oldMessages) => [
                    ...oldMessages,
                    { type: 'error', message: (ex as Error).message }
                  ])
                }
              }
            }
          ]}
          handlePageChange={handlePageChange}
          resultsPerPage={tableSize}
          resultsNumber={countRegister}
          totalPages={countPages}>
          <PerformanceTable klikkers={tableData} />
        </TableWrapper>
      </div>
    </section>
  )
}
