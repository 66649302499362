import styles from './index.module.scss'
import FeatherIcon from 'feather-icons-react'
import ReactPaginate from 'react-paginate'
import { useEffect, useState } from 'react'

interface IElementHeader {
  icono: string
  label: string
  disabled?: boolean
  handleClick: () => void | Promise<void>
}

interface ITableWrapperProps {
  title?: string
  elements?: IElementHeader[]
  children?: React.ReactNode
  noPaginate?: boolean
  totalPages?: number
  resultsNumber?: number
  resultsPerPage?: number
  handlePageChange?: (page: number) => void
  onButtonClick?: (action: string) => void
  disabled?: boolean
}

export default function TableWrapper({
  title = 'Titulo de la tabla',
  elements = [],
  children,
  noPaginate = false,
  totalPages = 1,
  resultsNumber = 1,
  resultsPerPage = 20,
  handlePageChange,
  disabled = true
}: ITableWrapperProps) {
  const [activePage, setActivePage] = useState(1)
  const [elementsStatus, setElementsStatus] = useState<boolean[]>(
    elements.map(() => false)
  )
  const rangeStart = 1 + resultsPerPage * (activePage - 1)
  const rangeEnd =
    resultsNumber >= resultsPerPage * activePage
      ? resultsPerPage * activePage
      : resultsNumber
  useEffect(() => {
    if (elements.length === 0) return
    setElementsStatus(elements.map((value) => value.disabled ?? false))
  }, [elements])

  return (
    <div className={styles.root}>
      <div className={styles['root__header-table']}>
        <h2 className={styles['root__header-table__title']}>{title}</h2>
        <div className={styles['root__header-table__container-elements']}>
          {elements.length > 0 && (
            <div className={styles['root__header-table__elements']}>
              {/* <div
                className={
                  styles['root__header-table__elements__change-more-less']
                }>
                <button>
                  <FeatherIcon
                    icon="repeat"
                    className={styles['root__header-table__button-icon']}
                  />
                  Ordenar
                </button>
              </div> */}
              {elements.map((element, key) => (
                <button
                  key={key}
                  type={'button'}
                  aria-label={'Botón de ' + element.label}
                  className={styles['root__header-table__button']}
                  onClick={async () => {
                    setElementsStatus((oldStatus) =>
                      oldStatus.map((oldValue, oldKey) =>
                        oldKey === key ? true : oldValue
                      )
                    )
                    await element.handleClick()
                    setElementsStatus((oldStatus) =>
                      oldStatus.map((oldValue, oldKey) =>
                        oldKey === key ? element.disabled ?? false : oldValue
                      )
                    )
                  }}
                  disabled={elementsStatus[key]}>
                  {element.icono !== undefined && (
                    <FeatherIcon
                      icon={element.icono}
                      className={styles['root__header-table__button-icon']}
                    />
                  )}
                  {element.label}
                </button>
              ))}
            </div>
          )}
          <span className={styles['root__header-table__results']}>
            {resultsNumber > 0
              ? `Resultados: ${rangeStart}-${rangeEnd} de ${resultsNumber}`
              : ''}
          </span>
        </div>
      </div>
      <div className={styles.root__table}>{children}</div>
      <div className={styles['root__footer-table-wrapper']}>
        {!noPaginate && resultsNumber > 0 && (
          <ReactPaginate
            previousLabel={<FeatherIcon icon="chevron-left" />}
            previousClassName={styles.pagination__previous}
            previousLinkClassName={styles['pagination__previous-link']}
            nextLabel={<FeatherIcon icon="chevron-right" />}
            nextClassName={styles.pagination__next}
            nextLinkClassName={styles['pagination__next-link']}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={totalPages}
            pageClassName={styles.pagination__page}
            onClick={(data) => {
              const value = data.selected + 1
              handlePageChange?.(value)
              setActivePage(value)
            }}
            marginPagesDisplayed={1}
            pageRangeDisplayed={5}
            onPageChange={(data) => {
              const value = data.selected + 1
              handlePageChange?.(value)
              setActivePage(value)
            }}
            containerClassName={styles.pagination}
            activeClassName={styles.active}
            disabledClassName={styles.disabled}
          />
        )}
      </div>
    </div>
  )
}
