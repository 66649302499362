import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import { useEffect } from 'react'

export interface IMessageToast {
  type: 'info' | 'warning' | 'error' | 'success'
  message: string
}
interface IMessageToastsProps {
  messages?: IMessageToast[]
  dismissible?: boolean
  onDismiss?: (toastIndex: number) => void
  autoDismissTime?: number
}

export default function MessageToasts({
  messages = [],
  dismissible = false,
  onDismiss = () => {},
  autoDismissTime = 5000
}: IMessageToastsProps) {
  useEffect(() => {
    const timers = messages.map((_, index) => {
      return setTimeout(() => {
        onDismiss(index)
      }, autoDismissTime)
    })

    return () => {
      timers.forEach((timer) => clearTimeout(timer))
    }
  }, [messages, onDismiss, autoDismissTime])

  return (
    <ul className={styles.root} aria-label={'Listado de mensajes'}>
      {messages.map((toast, index) => (
        <li
          key={index}
          className={
            styles.root__toast + ` ${styles[`root__toast--${toast.type}`]}`
          }>
          <span className={styles.root__message}>{toast.message}</span>
          {dismissible && (
            <button
              type={'button'}
              className={styles.root__dismiss}
              aria-label={'Descartar'}
              onClick={() => {
                onDismiss(index)
              }}>
              <FeatherIcon
                icon="x"
                role={'img'}
                className={styles.root__icon}
              />
            </button>
          )}
        </li>
      ))}
    </ul>
  )
}
